import React from 'react'
import './Who.css'
import whoImage from '../assets/whoImage.png'

const Who = () => {
  return (
    <section id="who">
        <div className='who'>
            <div className='container'>

                {/* Left Side */}
                <div className='left'>
                    
                    <h1>Who We Are</h1> 
                    <p>MKAN is a team of professionals that makes life easier for our
              clients when making a cryptocurrency exchange. To remain one of
              the best exchangers in the Dubai market, we are helped by the main
              principle of our company — the comfort and confidentiality of our
              customers.</p>
                    
                {/* <div className='input-container'>
                        <input type='email' placeholder='Enter your email' />
                        <button className='btn'>Learn More</button>
    </div> */}
                </div>


                {/* Right Side */}
                <div className='right'>
                    <div className='img-container'>
                        <img src={whoImage} alt=''/>
                    </div>
                </div>
            </div>
        </div>
        </section>
  )
}

export default Who