import React from 'react'
import './Hero.css'
import TBMKAN from '../assets/tblMkan.png'

const Hero = () => {
    return (
        <section id="hero">
        <div className='hero'>
            <div className='container'>

                {/* Left Side */}
                <div className='left'>
                    <p>Buy & Sell cryptocurrency</p>
                    <h1>Exchange cryptocurrencies with cash.</h1> 
                    <p>Available cryptocurrencies for exchange: BTC, USDT, USDC.</p>
                    
                {/* <div className='input-container'>
                        <input type='email' placeholder='Enter your email' />
                        <button className='btn'>Learn More</button>
    </div> */}
                </div>


                {/* Right Side */}
                <div className='right'>
                    <div className='img-container'>
                        <img src={TBMKAN} alt=''/>
                    </div>
                </div>
            </div>
        </div>
        </section>
    )
}

export default Hero
