import React from 'react'
import './Signup.css'

import Crypto from '../assets/trade.png'

const Signup = () => {
    return (
        <section id="how">
        <div className='signup'>
            <div className='container'>
                {/* left */}
                <div className='left'>
                    <img src={Crypto} alt='' />
                </div>

                {/* right */}
                <div className='right'>
                    <h2>How buy or sell cryptocurrency?</h2>
                    <p>You can exchange BTC, USDT and USDC.</p>
                    {/* <div className='input-container'>
                        <input type='email' placeholder='Enter your email' />
                        <button className='btn'>Learn More</button>
    </div> */}
                    <h3>Step 1</h3>
                    <p>Contact the MKAN team via Telegram or phone number.</p>

                    <h3>Step 2</h3>
                   <p>Select a cryptocurrency to exchange.</p>

                    <h3>Step 3</h3>
                    <p>Agree with the manager on the exchange rate.</p>

                    <h3>Step 4</h3>
                    <p>Visit the MKAN office and make an exchange.</p>
                </div>

            </div>
        </div>
        </section>
    )
}

export default Signup
