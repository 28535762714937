import React from 'react'
import {FaFacebook, FaGithub, FaLinkedin, FaTwitter} from 'react-icons/fa'
import './Footer.css'
import mkantab from '../assets/MKANCoinWht.png'

const Footer = () => {
    return (
        <section id="contact">
        <div className='footer'>
            <div className='container'>
                <div className='col col-1'>
                    <img src={mkantab} alt='' />
                  
                </div>
               
                
                <div className='col'>
                    <h5>Company</h5>
                    <span className='bar'> </span>
                        
                   
                    <p><b>Email:</b> hello@mkancoin.com </p>
                    <p><b>Address:</b> Sheikh Zaid road Rawadat Al Wasl building office 211 </p>
                    <p><b>Time Table:</b>  9:00 - 17:00</p>
                </div>
                <div className='col'>
                    <h5>Social</h5>
                    <span className='bar'> </span>
                        <a href='/'><FaFacebook className='icon'/></a>
                        <a href='/'><FaTwitter className='icon'/></a>
                        <a href='/'><FaLinkedin className='icon'/></a>
                        <a href='/'><FaGithub className='icon'/></a>
                </div>
            </div>
            
        </div>
        </section>
    )
}

export default Footer
