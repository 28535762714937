import React, {useState} from 'react'
import {FaBars, FaTimes} from 'react-icons/fa'
import './Navbar.css'
import MKANL from '../assets/MKANCoin.png'

const Navbar = () => {
const [click, setClick] = useState(false)
const handleClick = () => setClick(!click)
const scrollToSection = (event, sectionId) => {
    event.preventDefault();
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };
    return (
        <div className='header'>
            <div className='container'>
                <img src={MKANL} alt='' />
               
                <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                    <li>
                        <a href='#hero' onClick={(e) => scrollToSection(e, 'hero')}>Home</a>
                    </li>
                    <li>
                        <a href='#featured' onClick={(e) => scrollToSection(e, 'featured')}>Featured</a>
                    </li>
                    <li>
                        <a href='#how' onClick={(e) => scrollToSection(e, 'how')}>How To</a>
                    </li>
                    <li>
                        <a href='#who' onClick={(e) => scrollToSection(e, 'who')}>Who We Are</a>
                    </li>
                    <li>
                        
                        <a href="#contact" onClick={(e) => scrollToSection(e, 'contact')}>Contact</a>
                    </li>
                </ul>

                {/* <div className='btn-group'>
                    <button className='btn'>Connect Wallet</button>
    </div> */}

                <div className='hamburger' onClick={handleClick}>
                    {click ? (<FaTimes />) : (<FaBars  size={20} style={{color: '#333'}}/>)}
                    
                </div>

            </div>

        </div>
    )
    
}


export default Navbar