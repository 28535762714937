import React from 'react'
// import axios from 'axios'
// import { FiArrowUpRight, FiArrowDown } from 'react-icons/fi'
import './Featured.css'
import cryptos from '../assets/cryptos.png'
import iconz from '../assets/advantageImg.png'

const Featured = () => {

    
    return (
        <section id="featured">
        <div className='featured'>
            <div className='container'>
                {/* Left */}
               
                <div className='left'>
                <img src={cryptos} alt='' />
                    {/*<h2>Explore top Crypto's Like Bitcoin, Ethereum, and Dogecoin</h2>
                    <p>See all available assets: Cryptocurrencies and NFT's</p>
    <button className='btn'>See More Coins</button>*/}
                </div>

                {/* Right */}

                <div className='right'>
                <h2>Why Use MKAN?</h2>
                    <div className='card'>
                        <div className='top'>
                            {/* <img src={BTC} alt='/' /> */}
                            <img src={iconz} alt='' />
                        </div>
                        <div>
                            <h5>Fast Exchange</h5>
                            <p>Contact the MKAN manager and make a quick exchange at a
                favorable rate in our office</p>
                        </div>

                
                    </div>


                    <div className='card'>
                        <div className='top'>
                            <img src={iconz} alt='' />
                        </div>
                        <div>
                            <h5>High Secure</h5>
                            <p>Your data and transactions are highly protected on our encrypted
                servers in Dubai</p>
                        </div>

                        
                    </div>


                    <div className='card'>
                        <div className='top'>
                            <img src={iconz} alt='' />
                        </div>
                        <div>
                            <h5>Privacy</h5>
                            <p>You can carry out transactions being sure your informations
                remain safe and private</p>
                        </div>

                        
                    </div>


                    <div className='card'>
                        <div className='top'>
                            <img src={iconz} alt='' />
                        </div>
                        <div>
                            <h5>Regulated</h5>
                            <p>MKAN is full regulated by law of Dubai</p>
                        </div>

                       
                    </div>
                  

                </div>
            </div>

        </div>
        </section>
    )
}

export default Featured
